.studentInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(".././../../assets/images/studentinfobg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.studentInfoContent {
  width: 350px;
  border-radius: 20px;
}

.studenOuterCrdParent {
  padding-bottom: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.studenInfoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
}


.noStudentInfoContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 20px !important;
  border: 2px solid white;
  padding: 3px;
  border-radius: 10px;
  color: #00c2cb;
  background-color: white;
  height: 10%;
  width: 20%;
}

.studentInfoImgdiv {
  position: absolute;
  top: -10%;
  display: flex;
  justify-content: center;
}

.studentCard {
  border-radius: 20px;
  width: 100%;
  margin-top: -4%;
}

.studenOuterCrd {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25%;
}

.studetLogoContainer {
  padding: 7px 0;
  background-color: white;
  width: 100%;
  border-radius: 13px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  display: flex;
  justify-content: space-between;

  margin-top: 18px;
}


.studentData {
  display: flex;
  width: 100%;
}
.studentName,.studentValue {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 0.85rem;
    line-height: 26px;
    color: white;
    font-weight: bold;
    margin: 3px 0;
}
.studentValue{
  font-weight: 300;
}
.universityLOgo,.scanner{
    width: 50%; 
}

.scanner canvas#qrCode {
    width: 80px !important;
    height: 80px !important;
}

.studentDataContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.studentStyling{
    width: 100%;
}
.studntApiData, .studentApiName {
  width: 210px;
    margin: auto;
    word-break: break-all;
    display: flex;
    padding-left:10px;
    padding-right: 10px;
    overflow: hidden;
}

.studentApiName{
  width: 175px;
}
.studentSpiner{
    height: 390px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.studentBgimg{
  width: 100%;
}
.studentBgimg svg{
  width: 510px;
}
.studentInfoImgdiv img{
  width: 100%;
}
.universityLOgo img{
  width: 35%;
}
.studentInfoInnerContainer{
height: 100%;
width: 100%;
}
// @media(max-width:1600px){
//   .studetLogoContainer {
//     height: 130px;
//   }
//   .studentInfoImgdiv{
//     left: 155px;
//   }
// }
// @media(max-width:1400px){
//   .universityLOgo img{
//     width: 30%;
//   }
//   .scanner canvas#qrCode {
//     width: 70px !important;
//     height: 65px !important;
// }
// .studetLogoContainer{
//   height: 100px;
// }
// .studentInfoContent{
//   height: 735px;
// }
// .studenOuterCrd {
//   height: 575px;
// }
// .studentCard {
//   height: 490px;
// }
//   .studentInfoImgdiv img{
//     width: 60%;
//   }
// }
// @media(max-width: 992px){
//   .studntApiData {
//     width: 110px;
//   }
//   .studentBgimg svg {
//     width: 470px;
// }
// .studenInfoContainer {
//   height: 800px
// }
// .studentInfoContent {
//   height: 700px;
// }
// .studenOuterCrd {
//   height: 540px;
// }
// .studentCard {
//   height: 450px;
// }
// }