.analyticButtonSelector{
    display: flex;
    justify-content: flex-start;
    height: 50px;
    margin-top: 5%;
    padding: 5px 20px 5px 20px;
    color: #00c2cb;
}
.analyticsHomePageOuterContainer {
    border: 2px solid #00c2cb;
  border-radius: 10px;
  height: 500px;
  margin: auto;
  position: relative;
   }



.analyticUserTable{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    padding: 10px 10px 10px 13px;
   
}

.react-datepickerStyle{
    text-align: center;
    font-weight: 500; 
    border-radius: 4px !important;
    border-block-color:  #05d3de;
    border-left-color:  #06deea;
    border-right-color:  #06deea;
    color: black;
}

.selectOption{
    border: 2px solid !important;
    border-block-color:  #05d3de !important;
    border-left-color:  #06deea !important; 
    border-right-color:  #06deea !important;
    border-radius: 4px !important;
    padding: 0px 0px !important;
    height: 29px !important;
    margin-left: 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
    color: black;
    width: 165px;
}
.analyticTotalDiv{
    background-color: #5ce1e6 !important;
    border: 1px solid #5ce1e6 !important;
    border-radius: 4px;
    margin-top: -0.2% !important;
    color: #fff;
    background: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 43px;
    height: 80%;
    padding: 4px;
    font-weight: 500;

}

.btnStyle {
     margin-top: -1.25px ;
    }

.ant-table table{
    text-align: center !important; 
}

.ant-table-thead > tr > th {
    text-align: center !important;
}

.spinDiv{
    height: 295px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userTimeButtonContainer {
    display: flex;
    justify-content: space-between;
    width: 20%;
}

.userHeading{
    background: #0a0a0a;
    position: absolute;
    width: 15%;
    top: -6%;
    left: 5%;
}