.container {
  display: flex;
}
main {
  width: 100%;
}
.sidebar {
  background: #5ce1e6;
  color: black;
  height: 100vh;
  width: 250px;
  transition: all 0.5s;
}

.sidebarOpen {
  width: 80%;
}

.sidebarClose {
  width: 80%;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 10px;
  justify-content: center;
}
.logo {
  font-size: 30px;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 0px;
  justify-content: center;
}
.link {
  display: flex;
  color: #000;
  padding: 15px 15px;
  gap: 15px;
  transition: all 0.5s;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  display: flex;
}
.clubLogo{
  width: 25px;
}
.clubLogo:hover{
  color: #5ce1e6;
}
.clubColoredLogo{
  width: 25px;
  color: #5ce1e6 !important;
  background-color: red ;
}
.link:hover {
  background: black;
  color: #5ce1e6;
  transition: all 0.5s;
}
.active{
  background: black;
  color: #5ce1e6;
  display: flex;
}
.icon,
.link_text {
  font-size: 20px;
}
.sidebarLogoutStyle {
  font-weight: 700;
  cursor: pointer;
}
.sidebarContentStyle {
  display: flex;
  flex-direction: column;
}
.logoutstyle {
  display: flex;
  color: #000;
  padding: 15px 15px;
  gap: 15px;
  transition: all 0.5s;
  background-color: #5ce1e6 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  display: flex;
}
.logoutstyle:hover {
  color: #5ce1e6 !important;
  background-color: #000 !important;
}
