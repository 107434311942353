.inputStyling {
  background: white !important;
  color: #0a0a0a80 !important;
  border: 1px solid rgba(255, 255, 255, 0.51);
  border-radius: 10px !important;
  height: 50px !important;
  text-align: center !important;
  font-family: "Helvetica" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}
