.analyticsContainer {
    background-color: #0A0A0A;
    color: white;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.analyticsW100 {
    width: 90%;
}



.analyticsHomePageOuterContainer {
    border: 2px solid #00c2cb;
    border-radius: 10px;
    height: 570px;
    margin: auto;
    position: relative;

}

.analyticsUserCount {
    border-radius: 0.375rem;
    flex-wrap: wrap;
    background-color: #5ce1e6;
    color: #0A0A0A;
    align-self: flex-end;
    padding: 10px;
    margin: 13px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
}

.pieChartContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}


.clubAnalyticsContainer {
    width: 100%;
    height: 87%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 55px;
    margin-top: 48px;
    overflow: scroll;
}

.applicationAnalyticsContainer{
    height: 90%;
    padding: 10px 55px;
    margin-top: 65px;
    overflow: scroll;
}

.clubCardMargin {
    margin: 5px 10px;
}

.modalTopContainer {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modalTopContainer1{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.applicationContainer{
    padding: 10px 20px 10px 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
}

.applicationGoContainer{
    width: 30%;
    display: flex;
    justify-content: end;
}

.timeButtonContainer {
    display: flex;
    justify-content: space-between;
    width: 30%;
}

.chartsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: aliceblue;
}

.noDataStyle {
    width: 100%;
    text-align: center;
    font-size: 50;
    font-weight: bold;
    opacity: 0.5;
}

.noDataStyleAnalytics{
    width: 100%;
    text-align: center;
    font-size: 50;
    font-weight: bold;
    color: #5ce1e6;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.btnStyle {
    border-radius: 0.375rem !important;
}

.selected-button {
    background-color: black !important;
    border-color: black !important;
}

 .btnStyleColor.button.ant-btn.ant-btn-primary {
    background-color: #5ce1e6 !important;
    border: 1px solid #5ce1e6 !important;
}

.toggleSwitchContainer {
    border-radius: 0.375rem;
    padding: 2px;
    width: max-content;
    margin-left: auto;
    margin-right: 0;
}

.toggleButton {
    border: 1px solid #5ce1e6;
    background: none;
    margin: 0 2px;
    border-radius: 0.375rem;
    padding: 3px 5px;
}

.toggleButtonSelected {
    background-color: #5ce1e6;
}

.institutePageHeading {
background: #0a0a0a;
position: absolute;
width: 40%;
margin-top: -6px;
}

.clubPageHeading {
background: #0a0a0a;
position: absolute;
width: 30%;
margin-top: -6px;
}

.applicationPageHeading {
    background: #0a0a0a;
    position: absolute;
    width: 45%;
    margin-top: -6px;
}

.react-datepicker__input-container{
    color: black !important;
}

.selectOptionStudent{
    border: 2px solid !important;
    border-block-color:  #05d3de !important;
    border-left-color:  #06deea !important; 
    border-right-color:  #06deea !important;
    border-radius: 0.375rem !important;
    padding: 0px 0px !important;
    height: 29px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width: 164px;
    margin-right: 5px;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;

}

.selectOptions{
    border: 2px solid !important;
    border-block-color:  #05d3de !important;
    border-left-color:  #06deea !important; 
    border-right-color:  #06deea !important;
    border-radius: 0.375rem !important;
    padding: 0px 0px !important;
    height: 29px !important;
    margin-left: 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
    color: black;
    width: 165px;
    margin-right: 1%;
}

.select::selection{
    border-block-color:  #05d3de !important;
    border-left-color:  #06deea !important; 
    border-right-color:  #06deea !important;
}

.chartDiv{
    height: 320px !important;
    display: flex;
    justify-content: center;
    background-color: white;
}

.a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea {
    border-block-color:  #05d3de !important;
    border-left-color:  #06deea !important; 
    border-right-color:  #06deea !important;
    border-radius: 0.375rem;
}

@media (max-width: 1600px) {
    .analyticsHomePageOuterContainer {
      height: 450px;
    }
    .noDataStyleAnalytics{
        height: 38vh;
    }
}

@media (max-width: 1800px) {
 //
}

@media (max-width: 2000px) {
 //
}


    
