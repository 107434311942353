.NLBtn {
  background-color: #5ce1e6 !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border: 1px solid #5ce1e6 !important;
  color: #0a0a0a !important;
  height: 50px !important;
  text-align: center !important;
  box-shadow: none !important;
  font-family: "Helvetica" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  margin-left: 10px;
}

.diabled {
  background-color: #dfdddd !important;
  border: 1px solid #dfdddd !important;
  color: black !important;
  height: 35px !important;
  border-radius: 0px !important;
  margin-left: 10px;
}
.largeBtndisabled {
  background-color: #dfdddd !important;
  border: 1px solid #dfdddd !important;
  color: #0a0a0a !important;
  font-family: Helvetica !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  height: 60px !important;
  line-height: 23px !important;
  width: 175px !important;
  border-radius: 10px !important;
  margin-left: 10px;
}
