.clubLogoGalleryContainer {
  background-color: #0a0a0a;
  height: 100vh;
}
.clubLogoInnerContainer {
  width: 80%;
  margin: auto;
}
.clubLogoPoweredText {
  height: 170px;
}
.clubLogoNLBtnStyling {
  display: flex;
  justify-content: end;
}
.clubLogoNLBtnStyling button.ant-btn.ant-btn-default.NLBtn {
  height: 60px !important;
  width: 175px !important;
  font-family: "Helvetica" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  background-color: #00c2cb !important;
  color: #0a0a0a !important;
  border: 1px solid #00c2cb !important;
}
.clubLogoPageOuterContainer {
  border: 2px solid #00c2cb;
  border-radius: 10px;
  height: 650px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clubLogopageHeading {
  background: #0a0a0a;
  position: absolute;
  width: 26%;
  top: -4%;
  left: 4%;
}
.clubLogopageHeading h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  color: #5ce1e6;
  background: transparent;
}
.custColStyling .ant-col.ant-col-8 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.logoinstitution {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #0a0a0a;
}
.clubLogopoweredText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: end;
  color: #00c2cb;
  display: block;
  padding-bottom: 22px;
}
.clubLogosScroll {
  width: 95%;
  margin: auto;
  height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
  visibility: hidden;
}
.scrollContent,
.clubLogosScroll:hover,
.clubLogosScroll:focus {
  visibility: visible;
}
.ant-row {
  align-items: center !important;
}

.ant-upload-list {
  display: flex;
  justify-content: end;
}
.ant-upload.ant-upload-select-picture-card {
  height: 70px !important;
  width: 200px !important;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  background-color: #00c2cb !important;
  color: #0a0a0a;
  border: 1px solid #00c2cb !important;
}
.logoContainer img {
  width: 100%;
}
.clubLogoInnerContainer .ant-row {
  display: flex;
}
.logoInputStyling {
  display: flex;
  justify-content: end;
  margin-right: 10px;
}
.logoInputStyling input.ant-input.inputStyling {
  text-align: center !important;
  background-color: #00c2cb !important;
  font-family: "Helvetica" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 23px !important;
  border: 1px solid #00c2cb !important;
  height: 70px !important;
}
.clubLogoGalleryImg{
  padding: 10px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  border: 2px solid #00c2cb;
}
.clubLogoGalleryImg img {
  width: 100%;
  height: 100% !important;
  object-fit: contain;
}
.clubLogoInnerContainer form.ant-form.ant-form-horizontal {
  height: 175px;
}
.clubLogosScroll .scrollContent .ant-spin-spinning {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-upload.ant-upload-drag {
  background-color: transparent !important;
  border: none !important;
}
span.anticon.anticon-close-circle.crossIcon {
  color: white;
  position: absolute;
  right: 0;
}
.logoClubName{
  margin-left: 6px;
  margin-top: 1%;
}
@media (max-width: 1600px) {
  .logoinstitution {
    font-size: 14px;
    line-height: 25px;
  }
  .clubLogoPageOuterContainer {
    height: 450px;
  }
  .clubLogopageHeading h1 {
    font-size: 20px;
    line-height: 32px;
  }
  .clubLogopoweredText {
    font-size: 18px;
  }
  .clubLogoPageOuterContainer {
    height: 460px;
  }
  .scrollContent {
    height: 30vh;
  }
}
@media (max-width: 1400px) {
  .clubLogopoweredText {
    font-size: 16px;
  }
  .clubLogoNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
    font-size: 18px !important;
  }
  .logoinstitution {
    font-size: 12px;
    line-height: 20px;
  }
  .clubLogoNLBtnStyling {
    padding-top: 0px;
  }
}
@media (max-width: 1200px) {
  .clubLogoNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
    height: 65px !important;
    width: 165px !important;
  }
  .logoInputStyling input.ant-input.inputStyling {
    height: 65px !important;
    width: 165px !important;
  }
  .clubLogopageHeading h1 {
    font-size: 20px;
  }
}
@media (max-width: 992px) {
  .clubLogoNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
    height: 60px !important;
    width: 140px !important;
  }
  .logoInputStyling input.ant-input.inputStyling {
    height: 60px !important;
    width: 140px !important;
  }
  .clubLogopoweredText {
    font-size: 16px;
    justify-content: center;
  }
  .clubLogosScroll {
    height: 30vh;
  }
  .clubLogopageHeading h1 {
    font-size: 16px;
    line-height: 32px;
  }
  .clubLogosScroll .scrollContent .ant-spin-spinning {
    height: 30vh;
  }
}
