.managerDetailInputScroll .ant-space.ant-space-vertical {
    width: 100%;
}
.managerDetailInputScroll {
  width: 50%;
  margin: auto;
  // height: 230px;
}

.managerDetailInputScroll label {
  color: #5ce1e6 !important;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 32px !important;
  line-height: 18px;
  width: 150px;
}

.managerDetailInputScroll .ant-col.ant-form-item-label {
  width: 165px;
}
.radioButtons{
  width: 900px;
}

.mangerDatailClubName{
  margin-left: 6px;
  margin-top: 2%;
  text-transform: uppercase;
}

.maxLength {
  background: #0a0a0a !important;
  position: absolute;
  top: -5%;
  left: 4%;
  width: 23%;
}

.emailHeading{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
}

.mangerDetailPageOuterContainer {
  border: 2px solid #00c2cb;
  border-radius: 10px;
  height: 500px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-form-item.ant-form-item-has-success {
   margin-bottom: 24px !important; 
}

@media(max-width:1600px){
  .radioButtons {
    width: 810px;
  }
  .mangerDetailPageOuterContainer {
    height: 545px;
  }
}
@media(max-width:1400px){
  .radioButtons {
    width: 640px;
  }
  .radioButtons.ant-radio-group.ant-radio-group-outline{
    font-size: 20px;
  }
}
@media (max-width: 1200px) {
  .manageretailInputScroll {
    width: 75%;
  }
  .managerDetailInputScroll .ant-col.ant-form-item-label {
    width: 160px !important;
  }
}
